import React from 'react';
import ReactDOM from 'react-dom';
import localforage from 'localforage';
import { createStore } from 'polotno/model/store';
import { unstable_setRemoveBackgroundEnabled } from 'polotno/config';

import './index.css';
import App from './App';

unstable_setRemoveBackgroundEnabled(true);

const store = createStore({ key: 'XjCOwKLynJSVt0RRh0WH' });
window.store = store;

localforage.getItem('polotno-state', function (err, json) {
  if (json) {
    store.loadJSON(json);
  }
  if (!store.pages.length) {
    store.addPage();
  }
});

store.addFont({
  fontFamily: 'Faruma',
  url: '/fonts/Web_Faruma.ttf',
});

store.addFont({
  fontFamily: 'Mv Amaan Xp',
  url: '/fonts/Web_MvAmaanXP.ttf',
});

store.addFont({
  fontFamily: 'Mv AWaheed Bold',
  url: '/fonts/Web_MVAWaheed-Bold.ttf',
});

store.addFont({
  fontFamily: 'Mv Eamaan XP',
  url: '/fonts/Web_MvEamaanXP.ttf',
});

store.addFont({
  fontFamily: 'Mv Elaaf Formal',
  url: '/fonts/Web_MvElaafFormal.ttf',
});

store.addFont({
  fontFamily: 'Mv Elaaf Lite',
  url: '/fonts/Web_MvElaafLite.ttf',
});

store.addFont({
  fontFamily: 'Mv Elaaf Normal',
  url: '/fonts/Web_MvElaafNormal.ttf',
});

store.addFont({
  fontFamily: 'MV Faseyha',
  url: '/fonts/Web_MVFaseyha.ttf',
});

store.addFont({
  fontFamily: 'Mv Galan Normal',
  url: '/fonts/Web_MvGalanNormal.ttf',
});

store.addFont({
  fontFamily: 'Mv GroupX Avas',
  url: '/fonts/Web_MvGroupXAvas.ttf',
});

store.addFont({
  fontFamily: 'Mv Iyyu Formal',
  url: '/fonts/Web_MvIyyuFormal.ttf',
});

store.addFont({
  fontFamily: 'Mv Iyyu Nala',
  url: '/fonts/Web_MvIyyuNala.ttf',
});

store.addFont({
  fontFamily: 'Mv Iyyu Normal',
  url: '/fonts/Web_MvIyyuNormal.ttf',
});

store.addFont({
  fontFamily: 'Mv Radhun',
  url: '/fonts/Web_MvRadhun.ttf',
});

store.addFont({
  fontFamily: 'Mv Sehga Old',
  url: '/fonts/Web_MvSehgaOld.ttf',
});

store.addFont({
  fontFamily: 'MV Waheed',
  url: '/fonts/Web_MVWaheed.ttf',
});

store.addFont({
  fontFamily: 'Sangu Suruhee Regular',
  url: '/fonts/Web_SanguSuruhee-Regular.ttf',
});

store.on('change', () => {
  try {
    const json = store.toJSON();
    localforage.setItem('polotno-state', json);
  } catch (e) {}
});

ReactDOM.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>,
  document.getElementById('root')
);
